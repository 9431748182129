/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPropertyRecRatesQueryVariables = Types.Exact<{
  propertyId?: Types.InputMaybe<Types.Scalars['String']>;
  dateRange?: Types.InputMaybe<Types.DateRange>;
}>;


export type GetPropertyRecRatesQuery = { __typename?: 'Query', getPropertyRecRates?: Array<{ __typename?: 'RecReview', current_rate?: number | undefined, last_updated_date: string, override_rate?: number | undefined, priority?: number | undefined, property_id: string, rec_rate?: number | undefined, rec_status?: Types.RecReviewStatus | undefined, stay_date: string }> | undefined };

export type GetIntradayForecastQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetIntradayForecastQuery = { __typename?: 'Query', getIntradayForecast?: Array<{ __typename?: 'IntradayForecast', current_rate?: number | undefined, rate_variance?: number | undefined, rec_rate?: number | undefined, prev_rec_rate?: number | undefined, delta_market_rate?: number | undefined, last_hour_pkp?: number | undefined, expected_pkp?: number | undefined, actual_pkp?: number | undefined, expected_allday_pkp?: number | undefined, expected_allday_pkp_unc?: number | undefined, reasons?: string | undefined, snapshot_hour: number, stay_date: string, last_updated_date: string, total_fcst: number, transient_fcst: number, unc_transient_fcst?: number | undefined, a_fcst_sell?: number | undefined, b_fcst_sell?: number | undefined, c_fcst_sell?: number | undefined, d_fcst_sell?: number | undefined, e_fcst_sell?: number | undefined, f_fcst_sell?: number | undefined, g_fcst_sell?: number | undefined, h_fcst_sell?: number | undefined, a_status?: string | undefined, b_status?: string | undefined, c_status?: string | undefined, d_status?: string | undefined, e_status?: string | undefined, f_status?: string | undefined, g_status?: string | undefined, h_status?: string | undefined, last_current_rate?: number | undefined, derived_rate?: number | undefined, current_revenue?: number | undefined, fcst_rev?: number | undefined, fcst_occ_pct?: number | undefined, incr_revenue?: number | undefined } | undefined> | undefined };

export type GetRecRateQueryExplainersQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  dateRange: Types.DateRange;
}>;


export type GetRecRateQueryExplainersQuery = { __typename?: 'Query', getRecRateExplainers?: Array<{ __typename?: 'RecRateExplainer', stay_date?: string | undefined, pickup_ratio?: number | undefined, pickup_pace?: Types.RecRateExpectation | undefined, pricing_ratio?: number | undefined, pricing_pace?: Types.RecRateExpectation | undefined, rec_action?: Types.RecAction | undefined, incr_revenue?: number | undefined } | undefined> | undefined };

export type AcceptAllPropertyRecsMutationVariables = Types.Exact<{
  propertyId?: Types.InputMaybe<Types.Scalars['String']>;
  dateRange?: Types.InputMaybe<Types.DateRange>;
  lastUpdatedBy?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AcceptAllPropertyRecsMutation = { __typename?: 'Mutation', acceptAllPropertyRecs?: Array<{ __typename?: 'RecReview', current_rate?: number | undefined, derived_rate?: number | undefined, last_updated_date: string, market_rate?: number | undefined, override_rate?: number | undefined, property_id: string, rec_rate?: number | undefined, rec_status?: Types.RecReviewStatus | undefined, stay_date: string }> | undefined };

export type ResetPropertyRecsMutationVariables = Types.Exact<{
  propertyId?: Types.InputMaybe<Types.Scalars['String']>;
  dateRange?: Types.InputMaybe<Types.DateRange>;
}>;


export type ResetPropertyRecsMutation = { __typename?: 'Mutation', resetPropertyRecs?: Array<{ __typename?: 'RecReview', current_rate?: number | undefined, derived_rate?: number | undefined, last_updated_date: string, market_rate?: number | undefined, override_rate?: number | undefined, property_id: string, rec_rate?: number | undefined, rec_status?: Types.RecReviewStatus | undefined, stay_date: string }> | undefined };

export type UpdateRecMutationVariables = Types.Exact<{
  date: Types.Scalars['String'];
  propertyId: Types.Scalars['String'];
  newRate: Types.Scalars['Float'];
  status: Types.RecReviewStatus;
  lastUpdatedBy: Types.Scalars['String'];
}>;


export type UpdateRecMutation = { __typename?: 'Mutation', updateRec: { __typename?: 'RecReview', current_rate?: number | undefined, derived_rate?: number | undefined, last_updated_date: string, market_rate?: number | undefined, override_rate?: number | undefined, property_id: string, rec_rate?: number | undefined, rec_status?: Types.RecReviewStatus | undefined, stay_date: string } };


export const GetPropertyRecRatesDocument = gql`
    query GetPropertyRecRates($propertyId: String, $dateRange: DateRange) {
  getPropertyRecRates(propertyId: $propertyId, dateRange: $dateRange) {
    current_rate
    last_updated_date
    override_rate
    priority
    property_id
    rec_rate
    rec_status
    stay_date
  }
}
    `;

/**
 * __useGetPropertyRecRatesQuery__
 *
 * To run a query within a React component, call `useGetPropertyRecRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyRecRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyRecRatesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useGetPropertyRecRatesQuery(baseOptions?: Apollo.QueryHookOptions<GetPropertyRecRatesQuery, GetPropertyRecRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyRecRatesQuery, GetPropertyRecRatesQueryVariables>(GetPropertyRecRatesDocument, options);
      }
export function useGetPropertyRecRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyRecRatesQuery, GetPropertyRecRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyRecRatesQuery, GetPropertyRecRatesQueryVariables>(GetPropertyRecRatesDocument, options);
        }
export type GetPropertyRecRatesQueryHookResult = ReturnType<typeof useGetPropertyRecRatesQuery>;
export type GetPropertyRecRatesLazyQueryHookResult = ReturnType<typeof useGetPropertyRecRatesLazyQuery>;
export type GetPropertyRecRatesQueryResult = Apollo.QueryResult<GetPropertyRecRatesQuery, GetPropertyRecRatesQueryVariables>;
export const GetIntradayForecastDocument = gql`
    query GetIntradayForecast($propertyId: String!, $startDate: String, $endDate: String) {
  getIntradayForecast(
    propertyId: $propertyId
    startDate: $startDate
    endDate: $endDate
  ) {
    current_rate
    rate_variance
    rec_rate
    prev_rec_rate
    delta_market_rate
    last_hour_pkp
    expected_pkp
    actual_pkp
    expected_allday_pkp
    expected_allday_pkp_unc
    reasons
    snapshot_hour
    stay_date
    last_updated_date
    total_fcst
    transient_fcst
    unc_transient_fcst
    a_fcst_sell
    b_fcst_sell
    c_fcst_sell
    d_fcst_sell
    e_fcst_sell
    f_fcst_sell
    g_fcst_sell
    h_fcst_sell
    a_status
    b_status
    c_status
    d_status
    e_status
    f_status
    g_status
    h_status
    last_current_rate
    derived_rate
    current_revenue
    fcst_rev
    fcst_occ_pct
    incr_revenue
  }
}
    `;

/**
 * __useGetIntradayForecastQuery__
 *
 * To run a query within a React component, call `useGetIntradayForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntradayForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntradayForecastQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetIntradayForecastQuery(baseOptions: Apollo.QueryHookOptions<GetIntradayForecastQuery, GetIntradayForecastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntradayForecastQuery, GetIntradayForecastQueryVariables>(GetIntradayForecastDocument, options);
      }
export function useGetIntradayForecastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntradayForecastQuery, GetIntradayForecastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntradayForecastQuery, GetIntradayForecastQueryVariables>(GetIntradayForecastDocument, options);
        }
export type GetIntradayForecastQueryHookResult = ReturnType<typeof useGetIntradayForecastQuery>;
export type GetIntradayForecastLazyQueryHookResult = ReturnType<typeof useGetIntradayForecastLazyQuery>;
export type GetIntradayForecastQueryResult = Apollo.QueryResult<GetIntradayForecastQuery, GetIntradayForecastQueryVariables>;
export const GetRecRateQueryExplainersDocument = gql`
    query GetRecRateQueryExplainers($propertyId: String!, $dateRange: DateRange!) {
  getRecRateExplainers(propertyId: $propertyId, dateRange: $dateRange) {
    stay_date
    pickup_ratio
    pickup_pace
    pricing_ratio
    pricing_pace
    rec_action
    incr_revenue
  }
}
    `;

/**
 * __useGetRecRateQueryExplainersQuery__
 *
 * To run a query within a React component, call `useGetRecRateQueryExplainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecRateQueryExplainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecRateQueryExplainersQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useGetRecRateQueryExplainersQuery(baseOptions: Apollo.QueryHookOptions<GetRecRateQueryExplainersQuery, GetRecRateQueryExplainersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecRateQueryExplainersQuery, GetRecRateQueryExplainersQueryVariables>(GetRecRateQueryExplainersDocument, options);
      }
export function useGetRecRateQueryExplainersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecRateQueryExplainersQuery, GetRecRateQueryExplainersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecRateQueryExplainersQuery, GetRecRateQueryExplainersQueryVariables>(GetRecRateQueryExplainersDocument, options);
        }
export type GetRecRateQueryExplainersQueryHookResult = ReturnType<typeof useGetRecRateQueryExplainersQuery>;
export type GetRecRateQueryExplainersLazyQueryHookResult = ReturnType<typeof useGetRecRateQueryExplainersLazyQuery>;
export type GetRecRateQueryExplainersQueryResult = Apollo.QueryResult<GetRecRateQueryExplainersQuery, GetRecRateQueryExplainersQueryVariables>;
export const AcceptAllPropertyRecsDocument = gql`
    mutation AcceptAllPropertyRecs($propertyId: String, $dateRange: DateRange, $lastUpdatedBy: String) {
  acceptAllPropertyRecs(
    propertyId: $propertyId
    dateRange: $dateRange
    lastUpdatedBy: $lastUpdatedBy
  ) {
    current_rate
    derived_rate
    last_updated_date
    market_rate
    override_rate
    property_id
    rec_rate
    rec_status
    stay_date
  }
}
    `;
export type AcceptAllPropertyRecsMutationFn = Apollo.MutationFunction<AcceptAllPropertyRecsMutation, AcceptAllPropertyRecsMutationVariables>;

/**
 * __useAcceptAllPropertyRecsMutation__
 *
 * To run a mutation, you first call `useAcceptAllPropertyRecsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAllPropertyRecsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAllPropertyRecsMutation, { data, loading, error }] = useAcceptAllPropertyRecsMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      dateRange: // value for 'dateRange'
 *      lastUpdatedBy: // value for 'lastUpdatedBy'
 *   },
 * });
 */
export function useAcceptAllPropertyRecsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptAllPropertyRecsMutation, AcceptAllPropertyRecsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptAllPropertyRecsMutation, AcceptAllPropertyRecsMutationVariables>(AcceptAllPropertyRecsDocument, options);
      }
export type AcceptAllPropertyRecsMutationHookResult = ReturnType<typeof useAcceptAllPropertyRecsMutation>;
export type AcceptAllPropertyRecsMutationResult = Apollo.MutationResult<AcceptAllPropertyRecsMutation>;
export type AcceptAllPropertyRecsMutationOptions = Apollo.BaseMutationOptions<AcceptAllPropertyRecsMutation, AcceptAllPropertyRecsMutationVariables>;
export const ResetPropertyRecsDocument = gql`
    mutation ResetPropertyRecs($propertyId: String, $dateRange: DateRange) {
  resetPropertyRecs(propertyId: $propertyId, dateRange: $dateRange) {
    current_rate
    derived_rate
    last_updated_date
    market_rate
    override_rate
    property_id
    rec_rate
    rec_status
    stay_date
  }
}
    `;
export type ResetPropertyRecsMutationFn = Apollo.MutationFunction<ResetPropertyRecsMutation, ResetPropertyRecsMutationVariables>;

/**
 * __useResetPropertyRecsMutation__
 *
 * To run a mutation, you first call `useResetPropertyRecsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPropertyRecsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPropertyRecsMutation, { data, loading, error }] = useResetPropertyRecsMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useResetPropertyRecsMutation(baseOptions?: Apollo.MutationHookOptions<ResetPropertyRecsMutation, ResetPropertyRecsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPropertyRecsMutation, ResetPropertyRecsMutationVariables>(ResetPropertyRecsDocument, options);
      }
export type ResetPropertyRecsMutationHookResult = ReturnType<typeof useResetPropertyRecsMutation>;
export type ResetPropertyRecsMutationResult = Apollo.MutationResult<ResetPropertyRecsMutation>;
export type ResetPropertyRecsMutationOptions = Apollo.BaseMutationOptions<ResetPropertyRecsMutation, ResetPropertyRecsMutationVariables>;
export const UpdateRecDocument = gql`
    mutation UpdateRec($date: String!, $propertyId: String!, $newRate: Float!, $status: RecReviewStatus!, $lastUpdatedBy: String!) {
  updateRec(
    date: $date
    propertyId: $propertyId
    newRate: $newRate
    status: $status
    lastUpdatedBy: $lastUpdatedBy
  ) {
    current_rate
    derived_rate
    last_updated_date
    market_rate
    override_rate
    property_id
    rec_rate
    rec_status
    stay_date
  }
}
    `;
export type UpdateRecMutationFn = Apollo.MutationFunction<UpdateRecMutation, UpdateRecMutationVariables>;

/**
 * __useUpdateRecMutation__
 *
 * To run a mutation, you first call `useUpdateRecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecMutation, { data, loading, error }] = useUpdateRecMutation({
 *   variables: {
 *      date: // value for 'date'
 *      propertyId: // value for 'propertyId'
 *      newRate: // value for 'newRate'
 *      status: // value for 'status'
 *      lastUpdatedBy: // value for 'lastUpdatedBy'
 *   },
 * });
 */
export function useUpdateRecMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecMutation, UpdateRecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecMutation, UpdateRecMutationVariables>(UpdateRecDocument, options);
      }
export type UpdateRecMutationHookResult = ReturnType<typeof useUpdateRecMutation>;
export type UpdateRecMutationResult = Apollo.MutationResult<UpdateRecMutation>;
export type UpdateRecMutationOptions = Apollo.BaseMutationOptions<UpdateRecMutation, UpdateRecMutationVariables>;
export const namedOperations = {
  Query: {
    GetPropertyRecRates: 'GetPropertyRecRates',
    GetIntradayForecast: 'GetIntradayForecast',
    GetRecRateQueryExplainers: 'GetRecRateQueryExplainers'
  },
  Mutation: {
    AcceptAllPropertyRecs: 'AcceptAllPropertyRecs',
    ResetPropertyRecs: 'ResetPropertyRecs',
    UpdateRec: 'UpdateRec'
  }
}