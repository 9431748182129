import axios from 'axios';
import { windowDataExtractor } from './trackingHelper';
import { TrackingEnum, TrackingInput } from 'types/TrackingTypes';

export const datalakeIngestion = async (
  window: Window,
  trackingData: TrackingInput,
  token?: string
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const clientData = windowDataExtractor(window);
  const { value, ...restData } = trackingData;
  let data;

  if (trackingData.trackingEnum === TrackingEnum.CHANGE_RECORD_DATA_LAKE)
    data = {
      action:restData.action,
      trackingEnum:restData.trackingEnum,
      value: value ? JSON.parse(value) : '',
    };
  else
    data = {
      ...restData,
      ...clientData,
      value: value ? JSON.parse(value) : '',
    };

  return await axios.post(
    process.env.REACT_APP_DATA_LAKE_API_URL! + '/data-stream',
    data,
    { headers }
  );
};
