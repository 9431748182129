import './group-price-tool.scss';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { InputAdornment, Tooltip } from '@mui/material';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  addDays,
  formatDate,
  dataDate,
  dateFormatData,
  isDateAfter,
  dayRange,
  today,
  formatDateToCentral,
} from 'features/dates/date-helpers';
import {
  useDeleteGroupPricingMutation,
  useGetNetBenefitLazyQuery,
  useGetRoomsRemainingLazyQuery,
  useGetSavedGroupsQuery,
  useSaveGroupPricingMutation,
  useUpdateGroupPricingMutation,
} from '../../features/group-pricing/gql/_gen_/group-pricing.gql';
import { useEffect, useState } from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { AvailabilityTable } from './availability-table';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DropdownList } from 'components/DropdownList/DropdownList';
import { FaCheck } from 'react-icons/fa';
import { FieldType } from './slider-input';
import { GroupPricing } from 'graphql/gql-types';
import { MdDoNotDisturbOn } from 'react-icons/md';
import { MdMoreVert } from 'react-icons/md';
import { Modal } from 'components/Modal/Modal';
import { NetBenefitTable } from './net-benefit-table';
import SliderInput from './slider-input';
import TextField from '@mui/material/TextField';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { labels } from 'locales/en.label';
import { omit } from 'lodash';
import { useDispatch } from 'react-redux';
import { useModal } from 'components/Modal/useModal';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from 'features/users/context/userContext';

export interface GroupPriceToolInput {
  check_in: string;
  check_out: string;
  rooms_requested: number;
  rate_requested: number;
  commission: number;
  direct_var_opex: number;
  wash_factor: number;
  rebate: number;
  profit_margin: number;
  f_and_b_profit: number;
  meeting_space_benefit: number;
  comp_rooms: number;
  occ_fcst_override: number;
  adr_fcst_override: number;
  planners_adv_points: number;
  avg_stay_length: number;
}

export interface NetBenefitData {
  stay_date: string;
  total_capacity: number;
  current_otb: number;
  total_fcst: number;
  group_booked: number;
  current_rate: number;
  fcst_rev: number;
  py_priorstayovers: number;
  py_poststayovers: number;
}

export interface RoomAvailabilityData {
  stay_date: string;
  rt_bed_type: string;
  rt_description: string;
  rt_remaining: number;
  snapshot_date: string;
  property_id: string;
}

export function GroupPriceTool() {
  const [netBenefitData, setNetBenefitData] = useState<NetBenefitData[]>([]);
  const [roomData, setRoomData] = useState<RoomAvailabilityData[]>([]);
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [showRefetchNet, setShowRefetchNet] = useState<boolean>(false);
  const [showRefetchRooms, setShowRefetchRooms] = useState<boolean>(false);
  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupPricing | undefined>(
    undefined
  );
  const defaultValues = {
    check_in: today(),
    check_out: addDays(1).format(dateFormatData),
    rooms_requested: 20,
    rate_requested: 100,
    commission: 10,
    direct_var_opex: 35,
    wash_factor: 10,
    rebate: 0,
    profit_margin: 10,
    f_and_b_profit: 0,
    meeting_space_benefit: 0,
    comp_rooms: 0,
    occ_fcst_override: 0,
    adr_fcst_override: 0,
    planners_adv_points: 0,
    avg_stay_length: 1.25,
  };

  const { handleSubmit, control, getValues, setValue, resetField, reset } =
    useForm<GroupPriceToolInput>({
      defaultValues,
    });

  const dispatch = useDispatch();

  const { isShown: isSaveShown, toggle: toggleSave } = useModal();
  const { isShown: isLoadShown, toggle: toggleLoad } = useModal();
  const { isShown: isUpdateShown, toggle: toggleUpdate } = useModal();
  const { isShown: isDeleteShown, toggle: toggleDelete } = useModal();

  const { property, currencySymbol } = usePropertyContext();
  const propertyId = property?.propertyId;

  const { user } = useUser();

  const formValues = getValues();

  const numFormValues = Object.fromEntries(
    Object.entries(formValues).map(([key, value]) => [
      key,
      key === 'check_in' || key === 'check_out'
        ? value
        : isNaN(Number(value))
        ? value
        : Number(value),
    ])
  ) as GroupPriceToolInput;

  useEffect(() => {
    reset();
    setNetBenefitData([]);
    setRoomData([]);
  }, [propertyId, reset]);

  const { data: savedGroupsData, loading: loadingSaved } =
    useGetSavedGroupsQuery({
      skip: !propertyId || !user?.id,
      variables: {
        propertyId: propertyId,
        userId: user?.id || '',
      },
    });

  const savedGroups = savedGroupsData?.getSavedGroups;

  const [saveGroupPricing, { loading: saveLoading }] =
    useSaveGroupPricingMutation();

  const [updateGroupPricing, { loading: updateLoading }] =
    useUpdateGroupPricingMutation();

  const [deleteGroupPricing] = useDeleteGroupPricingMutation();

  const [getNetBenefitData, { loading: netBenefitLoading }] =
    useGetNetBenefitLazyQuery();

  const [getRoomsRemainingData, { loading: roomsLoading }] =
    useGetRoomsRemainingLazyQuery();

  const handleGetNetBenefit = (formValues: GroupPriceToolInput) => {
    setShowRefetchNet(false);
    const { check_in, check_out } = formValues;

    const endDate = dataDate(check_out);
    const startDate = dataDate(check_in);
    getNetBenefitData({
      variables: {
        startDate,
        endDate,
        propertyId: propertyId,
      },
      fetchPolicy: 'network-only',
      returnPartialData: false,
      onCompleted: (data) => {
        if (data?.getNetBenefit) {
          const formattedData = data.getNetBenefit.map((item) => ({
            ...item,
            stay_date: dataDate(item?.stay_date),
          }));
          setNetBenefitData(formattedData as NetBenefitData[]);
          setShowRefetchNet(false);
        }
      },
      onError: async () => {
        setShowRefetchNet(true);
        dispatch(alertAdded('error', labels.group_pricing.net_benefit_error));
      },
    });
  };

  const handleGetRoomsRemaining = (formValues: GroupPriceToolInput) => {
    setShowRefetchRooms(false);
    const { check_in, check_out } = formValues;

    const endDate = dataDate(check_out);
    const startDate = dataDate(check_in);
    getRoomsRemainingData({
      variables: {
        startDate,
        endDate,
        propertyId: propertyId,
      },
      fetchPolicy: 'network-only',
      returnPartialData: false,
      onCompleted: (data) => {
        if (data?.getRoomsRemaining) {
          const formattedData = data.getRoomsRemaining.map((item) => ({
            ...item,
            stay_date: dataDate(item?.stay_date),
          }));
          setRoomData(formattedData as RoomAvailabilityData[]);
          setShowRefetchRooms(false);
        }
      },
      onError: () => {
        setShowRefetchRooms(true);
        dispatch(
          alertAdded('error', labels.group_pricing.room_availability_error)
        );
      },
    });
  };

  const onSubmit: SubmitHandler<GroupPriceToolInput> = async (data) => {
    handleGetNetBenefit(data);
    handleGetRoomsRemaining(data);
  };

  const dates = dayRange(formValues.check_in, formValues.check_out);

  const handleCheckinChange = (date: string | null) => {
    const checkinDate = formatDate(date);
    const check_out = formatDate(formValues.check_out);
    setValue('check_in', checkinDate);
    if (isDateAfter(checkinDate, check_out)) {
      setValue('check_out', addDays(1, checkinDate).format(dateFormatData));
    }
  };

  const handleReset = () => {
    reset(defaultValues);
    setSelectedGroup(undefined);
    setNetBenefitData([]);
    setRoomData([]);
  };

  const handleSwitchModal = () => {
    toggleSave();
    toggleUpdate();
  };

  const handleSaveGroup = async () => {
    if (groupName === '') {
      setGroupNameError(true);
      return;
    }
    setGroupNameError(false);
    const group = getValues();
    const formattedGroup = omit(
      {
        ...group,
        check_in: formatDateToCentral(group.check_in),
        check_out: formatDateToCentral(group.check_out),
        property_id: propertyId,
        group_name: groupName,
        user_id: user?.id || '',
        user_email: user?.email || '',
      },
      ['id', 'created_at', 'updated_at']
    );
    await saveGroupPricing({
      variables: {
        group: formattedGroup,
      },
      onCompleted: (data) => {
        if (data?.saveGroupPricing) {
          const savedGroup = omit(data.saveGroupPricing, ['__typename']);
          setSelectedGroup(savedGroup);
          dispatch(
            alertAdded('success', labels.group_pricing.save_group_success)
          );
        }
      },
      onError: () => {
        dispatch(alertAdded('error', labels.group_pricing.save_group_error));
      },
      refetchQueries: ['GetSavedGroups'],
    });
    setGroupName('');
    toggleSave();
  };

  const handleUpdateGroup = async () => {
    const group = getValues();
    const formattedGroup = {
      ...group,
      check_in: formatDateToCentral(group.check_in),
      check_out: formatDateToCentral(group.check_out),
      updated_at: new Date().toISOString(),
    };

    await updateGroupPricing({
      variables: {
        group: formattedGroup,
      },
      onCompleted: (data) => {
        if (data?.updateGroupPricing) {
          setSelectedGroup(data.updateGroupPricing);
          dispatch(
            alertAdded('success', labels.group_pricing.update_group_success)
          );
        }
      },
      onError: () => {
        dispatch(alertAdded('error', labels.group_pricing.update_group_error));
      },
      refetchQueries: ['GetSavedGroups'],
    });
    toggleUpdate();
  };

  const handleDeleteGroup = () => {
    if (selectedGroup?.id) {
      deleteGroupPricing({
        variables: {
          id: selectedGroup.id,
        },
        onCompleted: () => {
          setSelectedGroup(undefined);
          dispatch(
            alertAdded('success', labels.group_pricing.delete_group_success)
          );
        },
        onError: () => {
          dispatch(
            alertAdded('error', labels.group_pricing.delete_group_error)
          );
        },
        refetchQueries: ['GetSavedGroups'],
      });
      toggleDelete();
    }
  };

  const handleSaveClicked = () => {
    if (selectedGroup) {
      toggleUpdate();
    } else {
      toggleSave();
    }
  };

  const handleCancelSave = () => {
    setGroupName('');
    setGroupNameError(false);
    toggleSave();
  };

  const handleLoadGroup = (group?: GroupPricing) => {
    if (group) {
      const selectedGroup = omit(group, ['__typename']);
      selectedGroup.check_in = selectedGroup.check_in?.split('T')[0];
      selectedGroup.check_out = selectedGroup.check_out?.split('T')[0];
      reset(selectedGroup);
      setSelectedGroup(selectedGroup);
      handleGetNetBenefit(group as GroupPriceToolInput);
      handleGetRoomsRemaining(group as GroupPriceToolInput);
    }
    toggleLoad();
  };

  const handleToggleAccepted = () => {
    if (selectedGroup) {
      const updatedGroup = {
        id: selectedGroup.id,
        accepted: !selectedGroup.accepted,
        updated_at: new Date().toISOString(),
      };
      updateGroupPricing({
        variables: {
          group: updatedGroup,
        },
        onCompleted: (data) => {
          if (data?.updateGroupPricing) {
            setSelectedGroup(data.updateGroupPricing);
            dispatch(
              alertAdded('success', labels.group_pricing.update_group_success)
            );
          }
        },
        onError: () => {
          dispatch(
            alertAdded('error', labels.group_pricing.update_group_error)
          );
        },
        refetchQueries: ['GetSavedGroups'],
      });
    }
  };

  return (
    <div className='group-price-tool'>
      {selectedGroup && (
        <div className='group-info'>
          <p className='selected-group'>
            Selected Group:{' '}
            <span className='group-name'>{selectedGroup.group_name}</span>
          </p>
          <p className='status-wrapper'>
            Status:{' '}
            <span
              className={selectedGroup.accepted ? 'accepted' : 'not-accepted'}
            >
              {selectedGroup.accepted ? (
                <span className='group-status'>
                  Accepted <FaCheck />
                </span>
              ) : (
                <span className='group-status'>
                  Not Accepted <MdDoNotDisturbOn />
                </span>
              )}
            </span>
          </p>
          <button
            className={`toggle-accept ${
              selectedGroup.accepted ? 'reject-button' : 'accept-button'
            }`}
            onClick={handleToggleAccepted}
          >
            {selectedGroup.accepted ? 'Reject' : 'Accept'}
          </button>
        </div>
      )}
      {isSaveShown && (
        <Modal
          isShown={isSaveShown}
          headerText='Save Group'
          isConfirmationModal={true}
          confirmBtnText='Save'
          onConfirm={handleSaveGroup}
          onCancel={handleCancelSave}
          hide={handleCancelSave}
          loading={saveLoading}
          messageBody={
            selectedGroup && (
              <div>
                <p className='switch-modal' onClick={handleSwitchModal}>
                  Click here to update group
                </p>
              </div>
            )
          }
          children={
            !saveLoading && (
              <TextField
                label='Group Name'
                value={groupName}
                required
                error={groupNameError}
                helperText={groupNameError ? 'Group Name is required' : ''}
                onChange={(e) => setGroupName(e.target.value)}
              />
            )
          }
        />
      )}
      {isUpdateShown && (
        <Modal
          isShown={isUpdateShown}
          headerText={`Update Group: ${selectedGroup?.group_name}`}
          isConfirmationModal={true}
          confirmBtnText='Update'
          onConfirm={handleUpdateGroup}
          onCancel={toggleUpdate}
          hide={toggleUpdate}
          messageBody={
            !updateLoading && (
              <div>
                <p className='switch-modal' onClick={handleSwitchModal}>
                  Click here to save as new group
                </p>
              </div>
            )
          }
          loading={updateLoading}
        />
      )}
      {isLoadShown && (
        <Modal
          isShown={true}
          headerText='Load Group'
          isConfirmationModal={false}
          hide={toggleLoad}
          loading={loadingSaved}
          children={
            <div className='group-list'>
              {savedGroups?.map((group) => (
                <div
                  key={group?.id}
                  className='group-list__item'
                  onClick={() => {
                    handleLoadGroup(group);
                  }}
                >
                  {group?.group_name}
                </div>
              ))}
            </div>
          }
        />
      )}
      {isDeleteShown && (
        <Modal
          isShown={isDeleteShown}
          headerText={`Delete Group: ${selectedGroup?.group_name}`}
          isConfirmationModal={true}
          confirmBtnText='Delete'
          onConfirm={handleDeleteGroup}
          onCancel={toggleDelete}
          hide={toggleDelete}
          messageBody='Are you sure you want to delete this group?'
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='group-price-tool__form'
      >
        <div className='basic-fields form-row'>
          <Controller
            name='check_in'
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                renderInput={(params) => <TextField {...params} />}
                value={field.value}
                label='Check In Date'
                minDate={today()}
                onChange={(date) => handleCheckinChange(date)}
              />
            )}
          />
          <Controller
            name='check_out'
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                renderInput={(params) => <TextField {...params} />}
                value={field.value}
                label='Check Out Date'
                onChange={(date) => setValue('check_out', formatDate(date))}
              />
            )}
          />
          <Controller
            name='rooms_requested'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Rooms Requested' />
            )}
          />
          <Controller
            name='rate_requested'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Rate Requested'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {currencySymbol}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name='commission'
            control={control}
            render={({ field }) => (
              <SliderInput
                label='Commission'
                field={field as FieldType}
                min={0}
                max={20}
                metric='%'
                setValue={setValue}
                resetField={resetField}
              />
            )}
          />
          <button className='submit' type='submit'>
            Submit
          </button>
          <DropdownList
            content={<MdMoreVert color='gray' style={{ fontSize: '24px' }} />}
            menu={[
              {
                id: 100,
                title: 'Reset All Fields',
                onClick: handleReset,
              },
              {
                id: 200,
                title: 'Save Group',
                onClick: handleSaveClicked,
              },
              {
                id: 300,
                title: 'Load Group',
                onClick: toggleLoad,
              },
              {
                id: 400,
                title: 'Delete Group',
                onClick: () => {
                  toggleDelete();
                },
              },
            ]}
          />
          <Tooltip
            title={showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
            arrow
            placement='top'
          >
            <button
              className='advanced-toggle'
              type='button'
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              {showAdvanced ? (
                <IoIosArrowUp
                  className='advanced-toggle__icon'
                  style={{ fontSize: '20px' }}
                />
              ) : (
                <IoIosArrowDown
                  className='advanced-toggle__icon'
                  style={{ fontSize: '20px' }}
                />
              )}
            </button>
          </Tooltip>
        </div>
        {showAdvanced && (
          <div className='advanced-fields'>
            <Controller
              name='direct_var_opex'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Direct Variable OPEX per Room Night'
                  field={field as FieldType}
                  min={0}
                  max={50}
                  metric={currencySymbol}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='wash_factor'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Wash Factor'
                  field={field as FieldType}
                  min={0}
                  max={50}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='rebate'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Rebate per Room Night'
                  field={field as FieldType}
                  min={0}
                  max={50}
                  metric={currencySymbol}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='profit_margin'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Profit Margin Required by Hotel'
                  field={field as FieldType}
                  min={0}
                  max={30}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='f_and_b_profit'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='F & B Profit per Group Room Night'
                  field={field as FieldType}
                  min={0}
                  max={50}
                  metric={currencySymbol}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='meeting_space_benefit'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Meeting Space Benefit (total for stay)'
                  field={field as FieldType}
                  min={0}
                  max={5000}
                  metric={currencySymbol}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='comp_rooms'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Comp Rooms per Night'
                  field={field as FieldType}
                  min={0}
                  max={formValues.rooms_requested}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='occ_fcst_override'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Occ Forecast Override'
                  field={field as FieldType}
                  min={-20}
                  max={20}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='adr_fcst_override'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='ADR Forecast Override'
                  field={field as FieldType}
                  min={-20}
                  max={20}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='planners_adv_points'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Planners Advantage Points per Dollar'
                  field={field as FieldType}
                  min={0}
                  max={3}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='avg_stay_length'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Average Stay Length'
                  field={field as FieldType}
                  min={1}
                  max={5}
                  step={0.01}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
          </div>
        )}
      </form>
      <div className='group-price-tool__tables'>
        {netBenefitLoading ? (
          <AiOutlineLoading3Quarters
            style={{
              animation: 'spin 1s linear infinite',
            }}
            className='loading-icon'
          />
        ) : (
          <NetBenefitTable
            data={netBenefitData}
            dates={dates}
            formValues={numFormValues}
          />
        )}
        {showRefetchNet && !netBenefitLoading && (
          <button
            onClick={() => handleGetNetBenefit(numFormValues)}
            className='refetch'
          >
            Refetch Net Benefit Data
          </button>
        )}
        {roomsLoading ? (
          <AiOutlineLoading3Quarters
            style={{
              animation: 'spin 1s linear infinite',
            }}
            className='loading-icon'
          />
        ) : (
          <AvailabilityTable data={roomData} dates={dates} />
        )}
        {showRefetchRooms && !roomsLoading && (
          <button
            onClick={() => handleGetRoomsRemaining(numFormValues)}
            className='refetch'
          >
            Refetch Room Availability Data
          </button>
        )}
      </div>
    </div>
  );
}
