import { Button } from 'components/ui/button';
import { labels } from 'locales/en.label';
import landingLogo from '../../images/landing_logo.png';
import masterBrandsLogo from '../../images/master_brands.png';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

export const Login = () => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    oktaAuth.setOriginalUri(history.location.pathname);
  }, [oktaAuth, history.location.pathname]);

  return (
    <div className='login'>
      <div className='tw-my-10'>
        <img src={landingLogo} alt='logo' className='tw-mx-auto tw-py-10' />
      </div>
      <Button
        className='tw-font-[Costa] !tw-flex tw-mx-auto tw-text-[18px] md:tw-text-[28px] tw-text-white tw-border-2 tw-border-white hover:tw-border-2 tw-rounded-lg tw-px-[20px] tw-pt-7 tw-pb-6 hover:tw-border-greenHover'
        variant={'green'}
        onClick={() => oktaAuth.signInWithRedirect()}
      >
        {labels.login.greeting}
      </Button>
      <img
        src={masterBrandsLogo}
        alt='master brands logo'
        className='tw-mx-auto tw-mt-[250px] tw-max-w-full tw-object-scale-down md:tw-object-none md:tw-max-w-none'
      />
    </div>
  );
};
