import 'features/rate-upload/components/rate-upload-button.scss';

import { IconButton, Tooltip } from '@mui/material';
import {
  selectPolling,
  selectUploading,
} from 'features/rate-upload/redux/rate-upload-selectors';
import { useEffect, useMemo, useState } from 'react';

import { MdRefresh } from 'react-icons/md';
import { PropMetrics } from 'features/home/types/prop-metrics';
import { RateUploadButton } from 'features/rate-upload/components/rate-upload-button';
import { RecReviewStatus } from 'types/RecReviewTypes';
import { ResetRateModal } from 'features/rate-upload/components/reset-rate-modal';
import { labels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';
import { useAppSelector } from 'redux/hooks';
import { usePropertyContext } from 'context/propertyContext';
import { useRateUpload } from 'features/rate-upload/hooks/use-rate-upload';
import { useUser } from 'features/users/context/userContext';

type RateOverrideFieldProps = {
  metrics: PropMetrics;
  setDirty?: (isDirty: boolean) => void;
};

export const RateOverrideField = ({
  metrics,
  setDirty,
}: RateOverrideFieldProps) => {
  const { isReadOnly } = useUser();
  const { handleChangeSingleRate, handleResetSingleRate, handleTriggerUpload } =
    useRateUpload();
  const polling = useAppSelector(selectPolling);
  const uploading = useAppSelector(selectUploading);
  const { currencySymbol } = usePropertyContext();

  const recRate = useMemo(() => metrics.rec_rate, [metrics.rec_rate]);
  const recStatus = useMemo(() => metrics.rec_status, [metrics.rec_status]);
  const [fieldValue, setFieldValue] = useState('');
  const [prefix, setPrefix] = useState(currencySymbol);
  const [showResetModal, setShowResetModal] = useState(false);

  useEffect(() => {
    if (metrics.override_rate) {
      setFieldValue(
        `${parseFloat(metrics.override_rate.toString()).toFixed(2)}`
      );
    }
  }, [metrics.override_rate]);

  const didUserOverride = recRate !== metrics.override_rate;
  const textValue = useMemo(
    () => `${prefix}${fieldValue}`,
    [prefix, fieldValue]
  );

  const handleBlur = () => {
    let formattedRate = parseFloat(fieldValue);
    setFieldValue(formattedRate.toFixed(2));
    handleChangeSingleRate({
      ...metrics,
      override_rate: formattedRate,
      rec_status: RecReviewStatus.PENDING,
    });
    setPrefix(currencySymbol);
  };

  const handleRateChange = (rateText: string) => {
    if (rateText === '') rateText = '0';
    if (rateText.length > 1 && rateText[0] === '0')
      rateText = rateText.slice(1);

    const validNumber = new RegExp(/^\d*\.?\d*$/);
    if (!validNumber.test(rateText)) return;

    setDirty && setDirty(true);
    setFieldValue(rateText);
  };

  const handleConfirmReset = (e: any) => {
    e?.stopPropagation();
    setShowResetModal(false);
    const originalValue = fieldValue;
    setFieldValue(recRate.toFixed(2));
    handleResetSingleRate(metrics.stay_date).then((res) => {
      if (res.errors) setFieldValue(originalValue);
    });
  };

  const closeResetModal = (e: any) => {
    e?.stopPropagation();
    setShowResetModal(false);
  };
  return (
    <span className='rate-override-field'>
      <ResetRateModal
        isShown={showResetModal}
        hide={closeResetModal}
        onCancel={closeResetModal}
        onConfirm={handleConfirmReset}
      />
      {recStatus === RecReviewStatus.PENDING && (
        <span>
          <RateUploadButton
            disabled={polling || uploading || isReadOnly}
            handleTriggerUpload={handleTriggerUpload}
            polling={polling}
            uploading={uploading}
            className='icon-btn'
          />
          <Tooltip title={labels.home.tooltip.reset} arrow placement='top'>
            <span>
              <IconButton
                disableFocusRipple
                disableRipple
                aria-label='reset'
                className='icon-btn'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowResetModal(true);
                }}
                disabled={!didUserOverride || isReadOnly}
              >
                <MdRefresh />
              </IconButton>
            </span>
          </Tooltip>
        </span>
      )}

      <input
        type='text'
        className='override-input'
        disabled={isReadOnly}
        style={{
          marginLeft: '4px',
          ...(didUserOverride && { color: styles.colorBlue }),
        }}
        value={textValue}
        onClick={(e) => {
          e.stopPropagation();
          const inputElement = e.target as HTMLInputElement;
          inputElement.select();
        }}
        onFocus={() => setPrefix('')}
        onBlur={(e) => {
          e.stopPropagation();
          handleBlur();
        }}
        onChange={(e) => {
          e.stopPropagation();
          handleRateChange(e.target.value);
        }}
      />
    </span>
  );
};
