import { dataDate, formatDate, today } from 'features/dates/date-helpers';
import {
  formattedCurrency,
  twoDecimalRateFormatter,
} from './tableCustomCellRender';

import { HtmlTooltip } from 'components/html-tooltip';
import { ICellRendererParams } from 'ag-grid-community';
import { RecReviewType } from 'types/RecReviewTypes';
import { labels } from 'locales/en.label';
import { selectProperty } from 'features/property/redux/selectors';
import { useAppSelector } from 'redux/hooks';
import { useUser } from 'features/users/context/userContext';

export const OverrideCell = ({ data, value }: ICellRendererParams) => {
  let didUserOverride = false;
  let oldSnapshot = false;
  const property = useAppSelector(selectProperty);
  const { currencyCode } = property;
  const { locale } = useUser();
  if (data) {
    const { last_updated_date, override_rate, rec_rate } =
      data as RecReviewType;
    didUserOverride = rec_rate !== override_rate;

    oldSnapshot = dataDate(last_updated_date) !== today();
  }

  const styleObject = {
    backgroundColor: 'rgba(255, 212, 100, 0.3)',
    width: '100%',
    borderRadius: '10px',
    padding: '5px 0',
    ...(didUserOverride && { color: '#476ced' }),
    ...(oldSnapshot && { textDecoration: 'underline' }),
  };
  const cellValue = formattedCurrency(
    value,
    locale,
    currencyCode,
    true,
    twoDecimalRateFormatter
  );
  if (oldSnapshot) {
    const tooltipText = labels.rec_review.dashboard.override_cell.old_snapshot;

    return (
      <HtmlTooltip
        placement='right'
        title={`${tooltipText} ${formatDate(data.last_updated_date)}`}
      >
        <span style={styleObject} className='rateToUploadCell'>
          {value ? cellValue : null}
        </span>
      </HtmlTooltip>
    );
  } else {
    return (
      <span style={styleObject} className='rateToUploadCell'>
        {value ? cellValue : null}
      </span>
    );
  }
};
