import { IRowNode, NewValueParams, RowSelectedEvent } from 'ag-grid-community';
import { RecReview, RecReviewStatus } from 'graphql/gql-types';
import { addDaysStr, dataDate } from 'features/dates/date-helpers';
import { maxBy, minBy } from 'lodash';
import {
  setAllRowsSelected,
  setPendingRecs,
  setPolling,
  setUploading,
  updatePending,
} from 'features/rate-upload/redux/rate-upload-slice';
import {
  useAcceptAllRecRates,
  useResetRecRates,
  useUpdateRecRate,
} from 'features/rec-rates/hooks/use-change-rec-rates';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { PropMetrics } from 'features/home/types/prop-metrics';
import { ResetRateModal } from '../components/reset-rate-modal';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { labels } from 'locales/en.label';
import { selectAllRowsSelected } from '../redux/rate-upload-selectors';
import { selectQueryInput } from 'features/my-view/redux/selectors';
import { useApolloError } from 'hooks/useApolloError';
import { useGetPropertyRecRates } from 'features/rec-rates/hooks/use-get-rec-rates';
import { usePropertyContext } from 'context/propertyContext';
import { useTriggerRateUploadLazyQuery } from '../gql/_gen_/trigger-upload.gql';
import { useUser } from 'features/users/context/userContext';

export const useRateUpload = () => {
  const POLLING_INTERVAL = 2000;
  const TIMEOUT_DURATION = 20000;

  const dispatch = useAppDispatch();
  const { handleApolloError } = useApolloError();
  const dataRef = useRef<IRowNode>();
  const { property } = usePropertyContext();
  const propertyId = property?.propertyId;
  const queryInput = useAppSelector(selectQueryInput);
  const allRowsSelected = useAppSelector(selectAllRowsSelected);
  const { user } = useUser();
  const userName = user?.login_id || 'BR2_USER';
  const [acceptAllRates] = useAcceptAllRecRates();
  const [changeRates] = useUpdateRecRate();
  const [resetRates] = useResetRecRates();
  const [triggerRateUpload] = useTriggerRateUploadLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [showWarning, setShowWarning] = useState(false);

  const { startPolling, stopPolling } = useGetPropertyRecRates({
    propertyId,
  });

  const getDateRange = (updateRows: RecReview[]) => {
    const { duration, startDate } = queryInput;

    if (duration && startDate) {
      return {
        startDate: dataDate(startDate),
        endDate: addDaysStr(duration - 1, startDate),
      };
    }

    const rowsStartDate = minBy(updateRows, 'stay_date')?.stay_date;
    const rowsEndDate = maxBy(updateRows, 'stay_date')?.stay_date;

    if (!rowsStartDate || !rowsEndDate) return;

    return {
      startDate: dataDate(rowsStartDate),
      endDate: dataDate(rowsEndDate),
    };
  };

  const handleCancelReset = () => {
    const row = dataRef.current;
    if (!row) return;
    row.setSelected(true);
    dataRef.current = undefined;
    setShowWarning(false);
  };

  // This is the function to call when a user selects a row or changes the
  // value of a cell in the rate upload table.
  const handleChangeSingleRate = async (data: RecReview | PropMetrics) => {
    dispatch(updatePending(data as RecReview));

    const {
      stay_date: date,
      property_id,
      override_rate: newRate,
      rec_status: status,
    } = data;
    if (!date || !property_id || !newRate || !status) return;
    changeRates({
      variables: {
        date: dataDate(date),
        propertyId: property_id,
        newRate,
        status: status as RecReviewStatus,
        lastUpdatedBy: userName,
      },
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  const handleConfirmReset = () => {
    const row = dataRef.current;
    if (!row) return;

    row.setData({
      ...row.data,
      last_updated_by: userName,
      rec_status: RecReviewStatus.R,
      override_rate: row.data.rec_rate,
    });

    handleChangeSingleRate(row.data);
    dataRef.current = undefined;
    setShowWarning(false);
  };

  const handlePolling = (grid?: React.RefObject<AgGridReact>) => {
    if (grid && !grid.current) return;

    dispatch(setPolling(true));
    startPolling(POLLING_INTERVAL);

    grid?.current?.api.forEachNode((row) => {
      const data = { ...row.data } as RecReview;
      if (data.rec_status === RecReviewStatus.P) {
        data.rec_status = RecReviewStatus.S;
        row.setData(data);
        row.setSelected(false);
      }
    });

    setTimeout(() => {
      dispatch(setPolling(false));
      stopPolling();
    }, TIMEOUT_DURATION);
  };

  const handleRateCellChange = async (params: NewValueParams) => {
    const row = params.node;
    if (!row) return;
    const data = row.data as RecReview;
    if (!data) return;

    // If the status is Review and the rate is the same as the rec rate,
    // then this was actually reset by the Reset Rates function. We do not want
    // to mark it as Pending.
    if (
      data.rec_status === RecReviewStatus.R &&
      data.override_rate === data.rec_rate
    )
      return;

    row.setDataValue('rec_status', RecReviewStatus.P);
    row.setSelected(true);
    return handleChangeSingleRate(params.data);
  };

  const handleResetSingleRate = async (date: string) => {
    const dateRange = { startDate: date, endDate: date };
    return resetRates({
      variables: {
        propertyId,
        dateRange,
      },
      onCompleted: async (data) => {
        if (data && data.resetPropertyRecs) {
          dispatch(setPendingRecs(data.resetPropertyRecs));
        }
      },
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  const handleResetRates = async (grid: React.RefObject<AgGridReact>) => {
    if (!grid.current) return;

    const confirm = window.confirm(labels.rec_review.reset_rates_modal.message);
    if (!confirm) return;

    const updateRows: RecReview[] = [];

    const rows = grid.current.api.getSelectedNodes();
    rows.forEach((row) => {
      const data = { ...row.data } as RecReview;

      // Don't make any changes to rows unless they are in Pending status.
      if (data.rec_status !== RecReviewStatus.P) return;

      data.last_updated_by = userName;
      data.rec_status = RecReviewStatus.R;
      data.override_rate = data.rec_rate;
      updateRows.push(data);
      row.setData(data);
      row.setSelected(false);
    });

    const dateRange = getDateRange(updateRows);

    await resetRates({
      variables: {
        propertyId,
        ...(dateRange && { dateRange }),
      },
      onCompleted: async (data) => {
        if (data && data.resetPropertyRecs) {
          dispatch(setPendingRecs(data.resetPropertyRecs));
        }
      },
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  const handleRowSelection = (event: RowSelectedEvent) => {
    const row = event.node;

    if (row.data.rec_status === RecReviewStatus.S) {
      return;
    }

    if (
      row.isSelected() === true &&
      row.data.rec_status !== RecReviewStatus.P
    ) {
      row.setData({
        ...row.data,
        last_updated_by: userName,
        rec_status: RecReviewStatus.P,
        override_rate:
          row.data.rec_rate === row.data.override_rate // If the rec_rate and override_rate do NOT match, we do NOT want to overwrite what the user put into override_rate.
            ? row.data.rec_rate
            : row.data.override_rate,
      });
      handleChangeSingleRate(row.data);
    } else if (row.isSelected() === false) {
      if (allRowsSelected) {
        dispatch(setAllRowsSelected(false));
      }
      if (
        row.data.rec_status === RecReviewStatus.R &&
        row.data.override_rate === row.data.rec_rate
      )
        return;

      const sameRate = row.data.override_rate === row.data.rec_rate;

      dataRef.current = row;

      const colId = event.api.getFocusedCell()?.column.getColId();

      if (colId !== 'override_rate' && !sameRate) {
        setShowWarning(true);
      } else if (colId !== 'override_rate' && sameRate) {
        handleConfirmReset();
      } else {
        row.setSelected(true);
      }
    }
  };

  const handleSelectAll = async (grid: React.RefObject<AgGridReact>) => {
    if (!propertyId) return;
    if (!grid.current) return;

    const updateRows: RecReview[] = [];
    const currentPending: RecReview[] = [];

    grid.current.api.forEachNode((row) => {
      const data = { ...row.data } as RecReview;

      // Don't make any changes to rows unless they are in Review status.
      if (data.rec_status === RecReviewStatus.P) currentPending.push(data);
      if (data.rec_status !== RecReviewStatus.R) return;

      data.last_updated_by = userName;
      data.rec_status = RecReviewStatus.P;
      data.override_rate = data.rec_rate;

      updateRows.push(data);
      row.setData(data);
      row.setSelected(true);
    });

    const dateRange = getDateRange(updateRows);

    await acceptAllRates({
      variables: {
        propertyId: propertyId,
        lastUpdatedBy: userName,
        ...(dateRange && { dateRange }),
      },
      onCompleted: (data) => {
        if (data && data.acceptAllPropertyRecs) {
          dispatch(
            setPendingRecs([...data.acceptAllPropertyRecs, ...currentPending])
          );
        }
      },
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  const handleSelectNone = async (grid: React.RefObject<AgGridReact>) => {
    if (!propertyId) return;
    if (!grid.current) return;

    const updateRows: RecReview[] = [];

    grid.current.api.forEachNode((row) => {
      const data = { ...row.data } as RecReview;

      // Don't make any changes to rows unless they are in Pending status.
      if (data.rec_status !== RecReviewStatus.P) return;

      data.rec_status = RecReviewStatus.R;

      data.override_rate = data.rec_rate;

      updateRows.push(data);
      row.setData(data);
      row.setSelected(false);
    });

    const dateRange = getDateRange(updateRows);

    await resetRates({
      variables: {
        propertyId: propertyId,
        ...(dateRange && { dateRange }),
      },
      onCompleted: async (data) => {
        if (data && data.resetPropertyRecs) {
          dispatch(setPendingRecs(data.resetPropertyRecs));
        }
      },
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  const handleTriggerUpload = async (grid?: React.RefObject<AgGridReact>) => {
    if (grid && !grid.current) return;

    dispatch(setUploading(true));
    if (!userName || !propertyId) return;

    return await triggerRateUpload({
      variables: {
        propertyId: propertyId,
        userName,
      },
      onCompleted: () => {
        dispatch(
          alertAdded('success', labels.rec_review.upload_rates_confirmation)
        );
        dispatch(setUploading(false));
        handlePolling(grid);
      },
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  const WarningModal = () => {
    return (
      <ResetRateModal
        hide={() => setShowWarning(false)}
        isShown={showWarning}
        onCancel={handleCancelReset}
        onConfirm={handleConfirmReset}
      />
    );
  };

  return {
    handleChangeSingleRate,
    handleRateCellChange,
    handleResetRates,
    handleResetSingleRate,
    handleRowSelection,
    handleSelectAll,
    handleSelectNone,
    handleTriggerUpload,
    WarningModal,
    getDateRange,
  };
};
