export type TrackingInput =
  | TrackingInputBase
  | AutoPilotTrackingInput
  | ChatBotTrackingInput;

export interface TrackingInputBase {
  action: string;
  page?: string;
  component?: string;
  propertyId?: string;
  seasonName?: string;
  seasonId?: string;
  stayDate?: string;
  trackingEnum?: TrackingEnum;
  value?: string;
}

export interface AutoPilotTrackingInput extends TrackingInputBase {
  autopilotPriority?: string;
  autopilotEnabled?: boolean;
  autopilotOption?: string;
}

export interface ChatBotTrackingInput extends TrackingInputBase {
  messageSent?: string;
  conversation?: string;
  chatAiResponse?: string;
}

export enum TrackingEnum {
  DATA_LAKE = 'DATA_LAKE',
  CHANGE_RECORD_DATA_LAKE = 'CHANGE_RECORD_DATA_LAKE',
  PG_DB = 'PG_DB',
}
