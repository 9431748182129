import { useEffect, useRef, useState } from 'react';

interface WebSocketHookOptions<T> {
  url: string;
  onMessage: (data: T) => void;
  onOpen?: () => void;
  onClose?: () => void;
}

export const useWebSocket = <T>({
  url,
  onMessage,
  onOpen,
  onClose,
}: WebSocketHookOptions<T>) => {
  const websocketRef = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!websocketRef.current) {
      const socket = new WebSocket(url);

      socket.onopen = () => {
        websocketRef.current = socket;
        setIsConnected(true);
        onOpen && onOpen();
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        onMessage(data);
      };

      socket.onclose = () => {
        websocketRef.current = null;
        setIsConnected(false);
        onClose && onClose();
      };

      return () => {
        socket.close();
      };
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, websocketRef]);

  return { websocket: websocketRef.current, isConnected };
};
